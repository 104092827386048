<template>
    <div class="detail-wrap">
        <div class="detail-box">
            <el-scrollbar style="height: 100%;">
                <ContentPreview :Content="DiseaseData.infoes">
                    <div slot="DetialPreview">
                        <h1>基本信息</h1>
                        <el-form v-if="DiseaseData != null && Object.keys(DiseaseData).length != 0" class="dialog-form" :model="DiseaseData" label-width="120px">

                            <el-row>
                                <el-col :span="24" v-if="DiseaseData.diseaseName">
                                    <el-form-item label="疾病名称">
                                        <div v-text="DiseaseData.diseaseName"></div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" v-if="DiseaseData.diseaseAlias">
                                    <el-form-item label="疾病别名">
                                        <div v-text="DiseaseData.diseaseAlias"></div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" v-if="DiseaseData.rank">
                                    <el-form-item label="权重">
                                        <div v-text="DiseaseData.rank"></div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" v-if="DiseaseData.code">
                                    <el-form-item label="疾病编码">
                                        <div v-text="DiseaseData.code"></div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" v-if="DiseaseData.category">
                                    <el-form-item label="疾病类型">
                                        <div v-text="DiseaseData.category"></div>
                                    </el-form-item>
                                </el-col>
                                <!-- <el-col :span="12">
                                    <el-form-item label="所属科室">
                                        <div v-text="DiseaseData.department.diseaseTypeName"></div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="所属分类">
                                        <div v-text="DiseaseData.diseaseType.diseaseTypeName"></div>
                                    </el-form-item>
                                </el-col> -->
                                <el-col :span="24" v-if="DiseaseData.infectious">
                                    <el-form-item label="传染病级别">
                                        <div v-text="DiseaseData.infectious"></div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-divider></el-divider>
                            <el-row>
                                <el-col :span="24" v-if="DiseaseData.diseaseInclude">
                                    <el-form-item label="包含关系">
                                        <div v-text="DiseaseData.diseaseInclude"></div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" v-if="DiseaseData.relation">
                                    <el-form-item label="关联疾病">
                                        <span v-for="item in DiseaseData.relation" v-text="`[${item.category}]${item.diseaseName}`"></span>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" v-if="DiseaseData.mainSymptom">
                                    <el-form-item label="主要症状">
                                        <div v-text="DiseaseData.mainSymptom"></div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" v-if="DiseaseData.mainSign">
                                    <el-form-item label="主要体征">
                                        <div v-text="DiseaseData.mainSign"></div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-divider v-if="DiseaseData.recommendTest || DiseaseData.resultCompare"></el-divider>
                            <el-form-item label="推荐试验/测试" v-if="DiseaseData.recommendTest">
                                <div v-text="DiseaseData.recommendTest"></div>
                            </el-form-item>
                            <el-form-item label="结果比对" v-if="DiseaseData.resultCompare">
                                <!--富文本-->
                                <div v-html="DiseaseData.resultCompare"></div>
                            </el-form-item>
                            <el-divider v-if="DiseaseData.examines || DiseaseData.examineInfoes"></el-divider>
                            <el-form-item label="推荐检查" v-if="DiseaseData.examines">
                                <div v-text="DiseaseData.examines"></div>
                            </el-form-item>
                            <el-form-item label="检查比对" v-if="DiseaseData.examineInfoes">
                                <!--富文本-->
                                <div v-html="DiseaseData.examineInfoes"></div>
                            </el-form-item>
                            <el-divider v-if="DiseaseData.clinical"></el-divider>
                            <el-form-item label="临床表现" v-if="DiseaseData.clinical">
                                <!--富文本-->
                                <div v-html="DiseaseData.clinical"></div>
                            </el-form-item>
                        </el-form>
                    </div>

                    <div slot="DetialPreview" v-if="DiseaseData==='中医' && DiseaseData.discriminates.length>0">
                        <h1>辨证论治</h1>
                        <el-card class="box-card" v-for="item in DiseaseData.discriminates" style="margin:15px;">
                            <div slot="header" class="clearfix">
                                <span v-text="item.name" style="margin-right:15px;"></span>
                                <el-tag v-text="item.category1" v-if="item.category1"></el-tag>
                                <el-tag v-text="item.category2" v-if="item.category2"></el-tag>
                                <el-tag v-text="item.category3" v-if="item.category3"></el-tag>
                            </div>
                            <div v-html="item.content" class="text item"></div>
                        </el-card>
                    </div>
                </ContentPreview>
            </el-scrollbar>
        </div>
    </div>
</template>
<script>
    import { Disease } from './Disease';
    export default {
        props: {
            diseaseHashKey: String
        },
        data() {
            var disease = new Disease(this.TokenClient, this.Services.Disease);
            return {
                DiseaseDomain: disease,
                DiseaseData: {},
            }
        },
        mounted() {
            this.Detial();
        },
        methods: {
            Detial() {
                var _this = this;
                _this.DiseaseDomain.DiseasesDetail(_this.diseaseHashKey,false,
                    function (data) {
                        _this.DiseaseData = data.data.disease;
                    },
                    function (err) { console.log(err); });
            },
        },
        watch: {
            diseaseHashKey(val, oldVal) {
                this.Detial();
            },
        }
    }
</script>
<style>
    .detail-wrap{
        padding: 0 0 50px;
        border-radius: 10px;
        height: 100%;
    }
    .detail-box{
        padding: 20px;
        background: #FFFFFF;
        border-radius: 10px;
        height: 100%;
    }
</style>
