
let Disease = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    let ChDiseaseSession = "ChDiseaseSession";
    let XDiseaseSession = "XDiseaseSession";

    this.Disease = function (pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/Diseases/Manager/Search';

        if (pageIndex) {
            url += '/' + pageIndex;
            if (keyWord) {
                url += '/' + keyWord;
            }
        } else if (keyWord) {
            url += '/1/' + keyWord;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.Synonym = function (keyWord, success, error) {
        var url = ServiceBaseAddress + "/api/Synonym";
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 分词测试
     * @param {*} type 
     * @param {*} content 
     * @param {*} success 
     * @param {*} error 
     */
    this.Analyze = function (type,content, success, error) {
        var url = ServiceBaseAddress + `/api/Synonym/Analyze/${type}/${content}`;
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.AddSynonym = function (param, success, error) {
        if (param) {
            var url = ServiceBaseAddress + '/api/Synonym';
            TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data);
                        success(jsonObj);
                    }
                }, error);
        }
    }
    this.editSynonym = function (param, success, error) {
        if (param) {
            var url = ServiceBaseAddress + '/api/Synonym';
            TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data);
                        success(jsonObj);
                    }
                }, error);
        }
    }
    this.RemoveSynonym = function (synonymId, success, error) {
        if (synonymId) {
            var url = ServiceBaseAddress + '/api/Synonym/' + synonymId;
            TokenClient.Delete(url, true, null, null,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data);
                        success(jsonObj);
                    }
                }, error);
        }
    }
    this.Find = function (keyWord, success, error) {
        var url = ServiceBaseAddress + "/api/Diseases/Manager/FindByName/" + keyWord;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    //查找西医疾病
    this.WesternFind = function (keyWord, success, error) {
        var url = ServiceBaseAddress + "/api/Diseases/Manager/FindByName/" + keyWord + "/西医";
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.Detial = function (diseaseId, success, error) {
        if (diseaseId) {
            var url = ServiceBaseAddress + '/api/Diseases/Manager/ViewDisease/' + diseaseId;
            TokenClient.Get(url, true, null, null,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data);
                        success(jsonObj);
                    }
                }, error);
        }
    }

    this.Add = function (disease, success, error) {
        if (disease) {
            var url = ServiceBaseAddress + '/api/Diseases/Manager/Disease';
            TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, disease,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data);
                        success(jsonObj);
                    }
                }, error);
        }
    }

    this.Edit = function (disease, success, error) {
        if (disease && disease.diseaseId) {
            var url = ServiceBaseAddress + '/api/Diseases/Manager/Disease';
            TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, disease,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data);
                        success(jsonObj);
                    }
                }, error);
        }
    }

    this.Remove = function (diseaseId, success, error) {
        if (diseaseId) {
            var url = ServiceBaseAddress + '/api/Diseases/Manager/Disease/' + diseaseId;
            TokenClient.Delete(url, true, null, null,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data);
                        success(jsonObj);
                    }
                }, error);
        }
    }

    this.WaitAudit = function (pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Diseases/Manager/WaitAudit';
        if (pageIndex) url += `/${pageIndex}`;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.Accept = function (diseaseId, success, error) {
        this.Audit(diseaseId, true, success, error);
    }

    this.Reject = function (diseaseId, success, error) {
        this.Audit(diseaseId, false, success, error);
    }

    this.Audit = function (diseaseId, statu, success, error) {
        var url = ServiceBaseAddress + `/api/Diseases/Manager/Audit/${diseaseId}/${statu}`;
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
    * 获取所有疾病
    * @param {any} success
    * @param {any} error
    */
    this.AllDisease = function (type, success, error) {
        var url = ServiceBaseAddress + '/api/DiseasePublic/GetAllDiseaseInfo/' + type;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取疾病详情数据
     * @param {any} success
     * @param {any} error
     */
     this.DiseasesDetail = function (params,isMark, success, error) {
        var url = ServiceBaseAddress + `/api/Diseases/${params}/${isMark}`;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { Disease }